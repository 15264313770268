<template>
  <div class="Member">
    <ul class="title">
      <li @click="back">
        <i class="iconfont icon-back"></i>
      </li>
      <li>会员说明</li>
    </ul>
    <ul class="content">
      <li v-for="(item, index) in memberlist" :key="index">{{ item.name }}</li>
    </ul>
    <div class="btn" @click="memeber">成为会员</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      memberlist: [
        {
          name: '1. 会员将获得系统完整功能体验',
        },
        {
          name: '2. 每个会员获得专属客服，通过电话和其他渠道给客户提供咨询、解答服务',
        },
        {
          name: '3. 会员将有权优先获得公司邮寄的纸质材料',
        },
        {
          name: '4. 我司会不定期以地区为单位，对会员进行线下服务和培训',
        },
        {
          name: '5. 会员将获得我司法组织的法律知识培训讲座参加权',
        },
      ],
    }
  },
  methods: {
    memeber() {
      console.log('ua...memeber')
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line
        AND2JS.toVipRechargeView()
      } else if (ua === 'fb-flutter-Webview') {
        // eslint-disable-next-line
        console.log('ua...flutter')
        window.toVipRechargeView.postMessage('{}')
      } else {
        this.$notify('该设备暂不支持此功能')
      }
    },
    back() {
      window.goback()
    },
  },
}
</script>

<style lang="stylus" scoped>
.Member
  width 100%
  height 100%
  .btn
    position absolute
    bottom 20px
    left 0px
    width 90px
    height 30px
    background #4B9EFB
    color #ffffff
    font-size 14px
    line-height 30px
    text-align center
    border-radius 15px
    left 35%
  .title
    width 100%
    border-bottom 1px solid #cccccc
    height 35px
    line-height 35px
    display flex
    li:nth-child(1)
      margin-left 10px
    li:nth-child(2)
      font-size 14px
      color #333
      font-weight 400
      margin-left 10px
      margin-top 2px
  .content
    width 95%
    margin-left 15px
    margin-top 5px
    li
      font-size 13px
      color #000
      line-height 30px
</style>

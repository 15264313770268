var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "Member" }, [
    _c("ul", { staticClass: "title" }, [
      _c("li", { on: { click: _vm.back } }, [
        _c("i", { staticClass: "iconfont icon-back" }),
      ]),
      _c("li", [_vm._v("会员说明")]),
    ]),
    _c(
      "ul",
      { staticClass: "content" },
      _vm._l(_vm.memberlist, function (item, index) {
        return _c("li", { key: index }, [_vm._v(_vm._s(item.name))])
      }),
      0
    ),
    _c("div", { staticClass: "btn", on: { click: _vm.memeber } }, [
      _vm._v("成为会员"),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }